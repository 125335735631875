import React, { useEffect, useState } from "react";
const { DateTime } = require("luxon");
import { mergedTripLink, tripTypeColor, TripVerificationIcon } from "../Helpers";
import { MergeCellsOutlined, CameraOutlined, ExclamationCircleOutlined, WarningOutlined, CheckCircleOutlined, DatabaseFilled } from '@ant-design/icons';
import { Modal, Checkbox, DatePicker, Input, message, Divider } from 'antd';
const { RangePicker } = DatePicker;
import axios from 'axios';

import {
    Typography, Tag,
    Radio,
    Button,
    InputNumber,
    Row,
    Col,
    Slider
} from 'antd';
const { Text, Title } = Typography;

import ProTable from '@ant-design/pro-table';

import TripEventSubTable from "./TripEventSubTable";
import { getTripDuration } from "../Helpers";
import TripTrailerMoal from "./TripTrailerModal";


export default function TripTable(props) {

    const [leftDuration, setLeftDuration] = useState(null);
    const [rightDuration, setRightDuration] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [tripIdSearch, setTripIdSearch] = useState(null);

    const [merging, setMerging] = useState(false);
    const [mergingOpen, setMergingOpen] = useState(false);
    const [mergeThreshold, setMergeThreshold] = useState(0);
    const [mergeMinutes, setMergeMinutes] = useState(1);
    const [mergeSeconds, setMergeSeconds] = useState(null);
    const [dateWithTime, setDateWithTime] = useState(false);
    const [mergeDateRange, setMergeDateRange] = useState({})

    const [trailerImagesModal, setTrailerImagesModal] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState({});
    const [tripTrailerImages, setTripTrailerImages] = useState([]);

    const [verificationTypes, setVerificationTypes] = useState({});

    const [editableFields, setEditableFields] = useState({});
    const [editingRowKey, setEditingRowKey] = useState({ trip_id: 0 });

    useEffect(() => {
        setMergeThreshold(mergeMinutes + (mergeSeconds / 60));
    }, [mergeMinutes, mergeSeconds])


    useEffect(() => {
        const result = props.verificationTypes.reduce((acc, item) => {
            acc[item.id] = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            return acc;
        }, {});
        setVerificationTypes(result);
    }, [props.verificationTypes])

    //Start editable trip fields

    const handleEditClick = (trip_id, value, field) => {
        setEditingRowKey({ trip_id: trip_id, field: field });
        setEditableFields({
            [field]: value
        });
    };

    const handleCancel = () => {
        setEditingRowKey({ trip_id: 0 }); // cancel edit
    };

    const handleSave = (trip_id) => {


        axios.post(window.vtrack_config.REACT_APP_API_URL + `/trips/${trip_id}/edit`, editableFields)
            .then(response => {
                //props.fetchData(); // refresh data after save
                setEditingRowKey({ trip_id: 0 });
                props.updateTripRow(trip_id, response.data);
            })
            .catch(error => {
                console.error("There was an error updating the trip!", error);
            });

    };

    //END editable trip fields

    function handleDateWithTimeCheck(e) {
        setDateWithTime(e.target.checked)

    }

    function handleTrailerImageModalOpen(tripId, selected) {
        setTrailerImagesModal(true);
        //fetchTripTrailerImages(tripId);

        setSelectedTrip(selected);

    }

    function handleDateChange(date) {
        let dateAfter = date[0] != null ? DateTime.fromHTTP(date[0].toString()) : null;
        let dateBefore = date[1] != null ? DateTime.fromHTTP(date[1].toString()) : null;
        console.log(dateAfter, dateBefore);
        dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        console.log(dateAfter, dateBefore);
        setMergeDateRange(
            {
                dateAfter,
                dateBefore
            }
        )
    }

    const onClose = () => {
        setMergingOpen(false)
    }

    const tripFilters = {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8, width: 220 }}>
                {/* first filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>
                            <Text>Range: </Text>
                            <InputNumber
                                value={leftDuration}
                                onChange={e => setLeftDuration(e)}
                                style={{ width: 60, marginLeft: 8, marginRight: 8 }}
                            />
                            <Text> - </Text>
                            <InputNumber
                                value={rightDuration}
                                onChange={e => setRightDuration(e)}
                                style={{ width: 60, marginLeft: 8 }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Slider
                            range
                            defaultValue={[leftDuration, rightDuration]}
                            value={[leftDuration, rightDuration]}
                            onChange={e => {
                                setLeftDuration(e[0]);
                                setRightDuration(e[1]);
                            }}
                            style={{ width: '100%' }}
                        />
                    </Row>

                    <Row style={{ marginBottom: 8 }}>
                        <Button
                            block
                            size="small"
                            onClick={() => {
                                // Reset the filter values
                                setLeftDuration(null);
                                setRightDuration(null);
                            }}
                        >
                            Reset
                        </Button>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={async () => {
                                setSelectedKeys([{ ...selectedKeys[0], duration_range: { less_than: rightDuration, more_than: leftDuration } }]);
                                confirm();
                            }}
                        >
                            Confirm
                        </Button>
                    </Row>
                </div>

                <Divider style={{ margin: '8px 0' }} />

                {/* second filter */}
                <div style={{ marginBottom: 16 }}>
                    <Row
                        type="flex"
                        gutter={10}
                        align="middle"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>Search by trip id:</Col>
                        <Col>
                            <Input
                                placeholder="Enter trip id..."
                                value={tripIdSearch}
                                onChange={e => {
                                    setTripIdSearch(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={() => {
                                // Apply second filter logic here
                                //props.searchTrip(undefined, undefined, { trip_id: tripIdSearch });
                                setSelectedKeys([{ ...selectedKeys[0], search: { value: tripIdSearch, field: "trip_id" } }]);
                                confirm();
                            }}
                        >
                            Apply Filter
                        </Button>
                    </Row>
                </div>
            </div>
        )


    }

    const pickupIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }
    const deliveryIncludeExclude = {
        filters: [
            {
                text: "Include",
                value: true
            },
            {
                text: "Exclude",
                value: false
            }
        ],
        filterMultiple: false
    }

    const columns = [
        {
            title: 'Trip Info', key: 'trip', dataIndex: "trip_duration", ...tripFilters, width: 180, sorter: true, render: (_val, record) =>
                <>
                    <Text strong>Trip ID: </Text>{mergedTripLink(record)}<br />
                    <Text strong>Tractor ID: </Text><Text>{record.truck.name}</Text><br />
                    <Text strong>Trip duration: </Text> {getTripDuration(record)}
                </>
        },
        {
            title: 'Created', key: 'created_message_time', dataIndex: 'created_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? DateTime.fromISO(val, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : val}<br />
                    Local: {!!val ? DateTime.fromISO(val).toFormat('dd/MM/yyyy HH:mm:ss') : val}

                </>

        },
        {
            title: 'Updated', key: 'updated_message_time', dataIndex: 'updated_message_time', width: 150, sorter: true, render: val =>
                <>
                    UTC: {!!val ? DateTime.fromISO(val, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : val}<br />
                    Local: {!!val ? DateTime.fromISO(val).toFormat('dd/MM/yyyy HH:mm:ss') : val}

                </>

        },
        {
            title: 'Status', key: 'status', width: 110, sorter: true, filters: [
                { text: "UNLOAD", value: 1 },
                { text: "LOAD", value: 2 },
                { text: "YARD SHIFT", value: 3 },
                { text: "VESSEL PICKUP", value: 4 },
                { text: "YARD PICKUP", value: 5 },
                { text: "NONE", value: null }
            ],
            render: (_val, record) => {
                let trip_type_name = record.trip_type_rel ? record.trip_type_rel.name : null
                switch (record.trip_type) {
                    case 1:
                        return <Tag color={tripTypeColor[1]}>{trip_type_name ? trip_type_name : "UNLOAD"}</Tag>;
                    case 2:
                        return <Tag color={tripTypeColor[2]}>{trip_type_name ? trip_type_name : "LOAD"}</Tag>;
                    case 3:
                        return <Tag color={tripTypeColor[3]}>{trip_type_name ? trip_type_name : "YARD SHIFT"}</Tag>;
                    case 4:
                        return <Tag color={tripTypeColor[4]}>{trip_type_name ? trip_type_name : "VESSEL PICKUP"}</Tag>;
                    case 5:
                        return <Tag color={tripTypeColor[5]}>{trip_type_name ? trip_type_name : "YARD PICKUP"}</Tag>;
                    default:
                        return <Tag color={tripTypeColor[0]}>{trip_type_name ? trip_type_name : "EMPTY"}</Tag>;
                }
            }

        },
        {
            title: 'Pickup', key: 'pickup', dataIndex: 'pickup_time', ...pickupIncludeExclude, width: 200, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.pickup_time ? DateTime.fromISO(record.pickup_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.pickup_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                                <Input value={editableFields.pickup_yard_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.pickup_yard_id, 'pickup_yard_id')}>Edit</Text>
                            </div>
                        )}

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                                <Input value={editableFields.pickup_deck_id} onChange={(e) => setEditableFields({ ...editableFields, pickup_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.pickup_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "pickup_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} className="edit-button"
                                    onClick={() => handleEditClick(record.trip_id, record.pickup_deck_id, "pickup_deck_id")}
                                >Edit</Text>
                            </div>
                        )}

                    </div>
                </React.Fragment>
        },
        {
            title: 'Discharge', key: 'discharge', dataIndex: 'discharge_time', width: 200, ...deliveryIncludeExclude, sorter: true,
            render: (_val, record) =>
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text strong>Time: </Text>
                            <Text>{!!record.discharge_time ? DateTime.fromISO(record.discharge_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.discharge_time}</Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Yard: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                                <Input value={editableFields.discharge_yard_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_yard_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_yard_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_yard_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => handleEditClick(record.trip_id, record.discharge_yard_id, 'discharge_yard_id')}>Edit</Text>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="editable-row">
                        <div>
                            <Text strong>Deck ID: </Text>
                            {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                                <Input value={editableFields.discharge_deck_id} onChange={(e) => setEditableFields({ ...editableFields, discharge_deck_id: e.target.value })} />
                            ) : (
                                <Text>{record.discharge_deck_id}</Text>
                            )}
                        </div>
                        {(editingRowKey.trip_id === record.trip_id && editingRowKey.field == "discharge_deck_id") ? (
                            <div>
                                <Button type="link" onClick={() => { handleSave(record.trip_id); }}>Save</Button>
                                <Button type="link" onClick={handleCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="edit-button">
                                <Text style={{ marginRight: 8, cursor: "pointer" }} onClick={() => handleEditClick(record.trip_id, record.discharge_deck_id, "discharge_deck_id")}>Edit</Text>
                            </div>
                        )}
                    </div>
                </React.Fragment>
        },
        {
            title: 'Trailer', key: 'trailer', dataIndex: 'trailer_number_time', filters: props.verificationTypes.map(item => ({
                text: item.name,
                value: item.id
            })), width: 200,
            sorter: true, render: (_val, record) =>
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{(record.user_verified != null && record.user_verified != "") ? record.user_verified : (record.approved_trailers && record.approved_trailers.length > 0) ? record.approved_trailers[0].trailer_number :
                        record.trailer_number
                    }</Text><br />
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? DateTime.fromISO(record.trailer_number_time, { zone: "utc" }).toFormat('dd/MM/yyyy HH:mm:ss') : record.trailer_number_time}</Text><br />
                    {(record.trailer_number != null && record.has_images) && (
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Row gutter={8}>
                                    <Col>
                                        <Text>


                                            {/* Verification type check and inline switch case */}

                                            {record["verification_type_rel"] ? (
                                                <>
                                                    {TripVerificationIcon(record["verification_type_rel"]["id"])} {verificationTypes[record["verification_type_rel"]["id"]]}
                                                </>
                                            ) : (
                                                " Unverified"
                                            )}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button type="primary"
                                    size="small"
                                    icon={<CameraOutlined />}
                                    onClick={() => { handleTrailerImageModalOpen(record.trip_id, record) }}
                                    style={{ marginLeft: 0, marginTop: 8 }}>View OCR</Button>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
        },
    ];


    function handleTableChange(pagination, filters, sorter) {
        setCurrentPage(pagination.current);
        if (!merging) {
            // Cant set selected keys in the filterdropdown to an object, only an array
            props.fetchData(pagination.current, undefined, {
                sortField: sorter.field,
                sortOrder: sorter.order,
                filters: filters
            })
        } else {
            let { dateAfter, dateBefore } = mergeDateRange
            dateAfter = dateAfter != null ? DateTime.fromISO(dateAfter) : null;
            dateBefore = dateBefore != null ? DateTime.fromISO(dateBefore) : null;
            if (!dateWithTime && dateAfter && dateBefore) {
                // Set dateAfter to the start of the day
                dateAfter = dateAfter.startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
                // Set dateBefore to the end of the same day
                dateBefore = dateBefore.endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
            } else {
                // If the dates are not on the same day (or with time is checked), format them as usual
                dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
                dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
            }
            props.fetchMergeTest(pagination.current)
        }
    }

    function handleFetchMerged() {
        setMerging(true);
        props.fetchMergeTest();
    }

    function mergingOnSubmit() {
        setMerging(true);
        let { dateAfter, dateBefore } = mergeDateRange
        console.log(dateAfter);
        dateAfter = dateAfter != null ? DateTime.fromISO(dateAfter) : null;
        dateBefore = dateBefore != null ? DateTime.fromISO(dateBefore) : null;
        if (!dateWithTime && dateAfter && dateBefore) {
            // Set dateAfter to the start of the day
            dateAfter = dateAfter.startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
            // Set dateBefore to the end of the same day
            dateBefore = dateBefore.endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
        } else {
            // If the dates are not on the same day (or with time is checked), format them as usual
            dateAfter = dateAfter ? dateAfter.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
            dateBefore = dateBefore ? dateBefore.toFormat("yyyy-MM-dd'T'HH:mm:ss") : null;
        }
        let params = { date: { dateAfter: dateAfter, dateBefore: dateBefore }, durationThreshold: mergeThreshold }
        props.fetchMergedTrips(undefined, undefined, params)
    }


    return (
        <>
            <style>{`
            .edit-button {
                visibility: hidden;
            }
            .edit-button > span {
                color: blue;
            }
            .editable-row:hover .edit-button {
                visibility: visible;
            }
                .ant-table-body {
                    max-height: calc(100vh - 240px) !important;
                }
            `}</style>
            {/* <div style={{ marginLeft: 32, marginTop: 16, display: 'flex', flexDirection: 'column' }}>
                <div>
                    <Button
                        type="primary"
                        size="large"
                        icon={<MergeCellsOutlined />}
                        onClick={() => setMergingOpen(true)}
                    >
                        Merge
                    </Button>
                    <Button
                        size="large"
                        onClick={async () => handleFetchMerged()}>
                        Fetch merged
                    </Button>
                </div>
                {merging && (
                    <React.Fragment>
                        <Text type="info" style={{ marginTop: 16 }}>In merging mode</Text>
                        <Button>Leave merging mode</Button>
                    </React.Fragment>
                )}
            </div> */}
            <Modal
                title="Merge trips"
                open={mergingOpen}
                onCancel={onClose}
                footer={[
                    <Button key="submit" type="primary" onClick={() => { mergingOnSubmit(); }}>
                        Merge
                    </Button>
                ]}
                style={{}}
            >
                <Divider />

                <Title level={5}>Threshold</Title>
                <Row gutter={16} type="flex" style={{ marginBottom: 8 }}>
                    <Col>Minutes: </Col>
                    <Col>
                        <InputNumber
                            value={mergeMinutes}
                            onChange={e => {

                                setMergeMinutes(e)
                            }} />
                    </Col>
                    <Col>Seconds: </Col>
                    <Col>
                        <InputNumber
                            value={mergeSeconds}
                            onChange={e => {
                                setMergeSeconds(e)
                            }} />
                    </Col>
                </Row>

                <Title level={5}>Date range</Title>
                <Row>
                    <Checkbox checked={dateWithTime} onChange={handleDateWithTimeCheck}>
                        Date with time
                    </Checkbox>

                    <RangePicker showTime={dateWithTime}
                        allowEmpty={[true, true]}
                        style={{ width: "100%" }}
                        onChange={(value) => { handleDateChange(value) }} />
                </Row>
            </Modal>
            <TripTrailerMoal
                initTrailerImagesModal={trailerImagesModal}
                setInitTrailerImagesModal={setTrailerImagesModal}
                selectedTrip={selectedTrip}
                updateTrip={props.updateTripRow}
            />

            <ProTable
                columns={columns}
                loading={props.loading}
                pagination={{
                    pageSize: props.page_size, current: currentPage, showSizeChanger: false, size: "small", hideOnSinglePage: false, style: { marginBottom: 0 }, total: props.count
                }}

                bordered={true}
                expandable={{
                    expandedRowRender: record => { return <TripEventSubTable tripId={record.trip_id} /> },
                    rowExpandable: () => 1 > 0,
                }}
                rowKey="trip_id"
                dataSource={props.data}
                scroll={{ y: props.scroll }}
                search={false}
                options={{ 'reload': () => { merging ? props.fetchMergeTest() : props.fetchData() } }}
                onChange={handleTableChange}
            />
        </>

    )

}
