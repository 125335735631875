import React, { useEffect, useState, useRef } from "react";

import { FunctionOutlined, LeftOutlined, PartitionOutlined, RightOutlined } from '@ant-design/icons';
import { Modal, Input, Table, ConfigProvider, Spin, Checkbox, message } from 'antd';
import {
    Typography,
    Button,
    Row,
    Col
} from 'antd';
const { Text, Link } = Typography;

import axios from 'axios';
import { Duration } from "luxon";

export default function TripTrailerMoal({ initTrailerImagesModal, selectedTrip, setInitTrailerImagesModal, updateTrip}) {
    const [tripId, setTripId] = useState("");
    const [trailerSelectedLoading, setTrailerSelectedLoading] = useState(false);
    const [trailerImagesLoading, setTrailerImagesLoading] = useState(false);
    const [tripTrailerImages, setTripTrailerImages] = useState([]);
    const [userApprovedChecked, setUserApprovedChecked] = useState(false);
    const [selectedTrailerImage, setSelectedTrailerImage] = useState(null);
    const [trailerImageLookup, setTrailerImageLookup] = useState([]);
    const [manualOverwrite, setManualOverwrite] = useState("");
    const [trailerImagesModal, setTrailerImagesModal] = useState(false);
    const [invalidTrip, setInvalidTrip] = useState(false);
    const [partialNumber, setPartialNumber] = useState(false);
    const [initialTripTrailer, setInitialTripTrailer] = useState([]);
    const tableContainerRef = useRef(null);

    useEffect(() => {
        if (selectedTrip && trailerImagesModal) {
            setTripId(selectedTrip["trip_id"]);
            fetchTripTrailerImages(selectedTrip["trip_id"]);
            //{ user_verified: record.user_verified, verification: record.verification_type_rel }
            const { user_verified, verification_type_rel } = selectedTrip;
            if (verification_type_rel && verification_type_rel["id"] === 3) {
                setInvalidTrip(true);
            }
            if (verification_type_rel && verification_type_rel["id"] === 4) {
                setPartialNumber(true);
            }
                
            if (user_verified != null) {
                setManualOverwrite(user_verified);
            } else {
                setManualOverwrite("");
            }
        }
    }, [selectedTrip, trailerImagesModal])

    //when modal is opened get trip id

    useEffect(() => {
        if (invalidTrip) {
            setManualOverwrite("");
            setUserApprovedChecked(false);
        }
    }, [invalidTrip])

    useEffect(() => {
        setTrailerImagesModal(initTrailerImagesModal);
    }, [initTrailerImagesModal])

    async function handleTripTrailerOk() {
        //setTrailerImagesModal(false);
        //setInitTrailerImagesModal(false);

        const modifiedRows = {};
        let referenceImages = []
        try {
            tripTrailerImages.forEach((currentRow, index) => {
                const initialRow = initialTripTrailer[index];
                const modifiedAttributes = {};

                // If partial number is save all the approved images
                if(partialNumber && currentRow.user_approved){
                    referenceImages.push(currentRow.trailer_number);
                }

                Object.keys(currentRow).forEach(key => {
                    if (currentRow[key] !== initialRow[key]) {
                        modifiedAttributes[key] = currentRow[key];
                    }
                })
                if (Object.keys(modifiedAttributes).length > 0) {
                    modifiedRows[currentRow.trailer_number] = modifiedAttributes; // Save using the trailer_number as the key
                }
            })

            if(!partialNumber) referenceImages = [selectedTrailerImage["id"]]

            if (Object.keys(modifiedRows).length > 0 || manualOverwrite != null) {

                const response = await axios.post(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/trailer_id_image/approve', {
                    changes: modifiedRows,
                    user_verified: manualOverwrite,
                    selected_images: referenceImages,
                    invalid_trip: invalidTrip,
                    partial_number: partialNumber
                });

                //Update the frontend with the updated data
                const { trip_id } = response.data;
                updateTrip(trip_id, response.data);
                resetTripTrailerState();
            }
        } catch (error) {
            console.error('Error sending approved trip IDs:', error);
            message.error(error.response.data.detail, 8);
        }
        
    }

    function handleTrailerImageSelect(trailerNumber) {
        const foundIndex = tripTrailerImages.findIndex(item => item.trailer_number == trailerNumber);

        if (foundIndex !== -1) {
            // Set the selectedTrailerImage using the found object
            setSelectedTrailerImage({
                data: tripTrailerImages[foundIndex]["image_data"],
                index: trailerImageLookup.findIndex(obj => obj.index === foundIndex),
                name: tripTrailerImages[foundIndex]["displayname"],
                id: tripTrailerImages[foundIndex]["trailer_number"]
            });
        } else {
            console.error(`Trailer with number ${trailerNumber} not found`);
        }
    }

    async function fetchTripTrailerImages(tripId) {
        setTrailerSelectedLoading(true);
        setTrailerImagesLoading(true);
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/trailer_id_image')
            .then(response => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        //Hide rows that dont have an image
                        const filteredData = response.data.filter((ocr) => ocr.image_data != null);
                        setTripTrailerImages(filteredData);
                        setInitialTripTrailer(filteredData);

                        let tempTrailerImageLookup = []
                        let selectedRow = -1;
                        for (let i = 0; i < filteredData.length; i++) {
                            if (filteredData[i]["image_data"] != "" && filteredData[i]["image_data"] != null) {

                                tempTrailerImageLookup.push({ index: i, count:filteredData[i]["updated_count"], confidence: filteredData[i]["confidence"] });
                            }
                            if (filteredData[i]["manual_overwrite"] || filteredData[i]["user_approved"]) {
                                setUserApprovedChecked(true);
                                if (filteredData[i]["user_approved"]) {
                                    selectedRow = i;
                                }
                            }
                        }
                        tempTrailerImageLookup.sort((a, b) => b.count - a.count);
                        let selectedIndex = 0;
                        let lookUpIndex = 0;
                        //if selected index was not set with the approved trailer image, set one with highest updated_count
                        if (selectedRow >= 0) {
                            lookUpIndex = tempTrailerImageLookup.findIndex((img) => { return img.index === selectedRow });
                            
                        }
                        selectedIndex = tempTrailerImageLookup[lookUpIndex]["index"];
                        setSelectedTrailerImage({ data: filteredData[selectedIndex]["image_data"], index: lookUpIndex, name: filteredData[selectedIndex]["displayname"], id: filteredData[selectedIndex]["trailer_number"] });
                        setTrailerImageLookup(tempTrailerImageLookup);
                        setTrailerImagesLoading(false);
                        setTrailerSelectedLoading(false);
                        scrollRowIntoView(filteredData[selectedIndex]["trailer_number"]);
                    }
                    else {
                        message.warning("No OCR images were found for " + tripId)
                        setTrailerImagesLoading(false);
                        setTrailerSelectedLoading(false);
                    }
                } else {
                    console.log(response);
                    message.error("Could not load OCR images for trip " + tripId + "...")
                    setTrailerImagesLoading(false);
                    setTrailerSelectedLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load OCR images for trip " + tripId + "...")
                setTrailerImagesLoading(false);
                setTrailerSelectedLoading(false);
            });
    }

    function resetTripTrailerState() {
        setTrailerImagesModal(false);
        setInitTrailerImagesModal(false);
        setTripTrailerImages([]);
        setSelectedTrailerImage(null);
        setUserApprovedChecked(false);
        setInvalidTrip(false);
        setPartialNumber(false);
        setManualOverwrite("");
    }

    const tripTrailerColumns = [
        {
            title: 'Trailer ID',
            dataIndex: 'trailer_number',
            key: 'trailer_number',
            render: text => <Text strong>{text}</Text>,
        },
        {
            title: 'Confidence',
            dataIndex: 'confidence',
            key: 'confidence',
            width: 32,
            sorter: (a, b) => a.confidence - b.confidence,
            sortDirections: ['descend', 'ascend'],
            render: text => <Text>{text}</Text>,
        },
        {
            title: 'Image',
            dataIndex: 'displayname',
            key: 'displayname',
            render: (imageData, record) =>
                <Text>{(imageData == null || imageData == "") ? record.image_id : imageData}</Text>
        },
        {
            title: 'Count',
            dataIndex: 'updated_count',
            key: 'updated_count',
            width: 32,
            sorter: (a, b) => a.updated_count - b.updated_count,
            defaultSortOrder: "descend",
            render: text => <Text>{text + 1}</Text>,
        },
        {
            title: partialNumber ? 'Partial' : 'Approved',
            dataIndex: 'user_approved',
            width: 32,
            key: 'user_approved',
            render: (checked, record) => <Checkbox defaultChecked={!invalidTrip && checked} checked={!invalidTrip && checked}
                onChange={(e) => handleTripTrailerChange(record.trailer_number, "user_approved", e.target.checked)}
                disabled={invalidTrip ? true : checked ? false : (manualOverwrite !== "" && manualOverwrite !== null && !partialNumber)} />,
        }
    ];

    function handleTripTrailerChange(trailerNumber, key, value) {
        for (let i = 0; i < tripTrailerImages.length; i++) {
            if (tripTrailerImages[i]["trailer_number"] == trailerNumber) {
                if (key == "user_approved") {
                    setTripTrailerImages(prevState => {
                        let updatedState = [];
                        if(!partialNumber){
                            updatedState = prevState.map(item => ({
                                ...item,
                                user_approved: item.trailer_number === trailerNumber ? value : false
                            }));
                        } else {
                            updatedState = prevState.map(item => ({
                                ...item,
                                user_approved: item.trailer_number === trailerNumber ? value : item.user_approved
                            }));
                        }
 
                        // Now use the updatedState to check for approvals
                        const approved = updatedState.some(item => item.user_approved);
                        setUserApprovedChecked(approved);
                        return updatedState;
                    });
                }
                if (key == "manual_overwrite") {
                    setTripTrailerImages(prevState =>
                        prevState.map(item =>
                            item.trailer_number === trailerNumber ? { ...item, [key]: value } : item
                        )
                    );
                }
            }
        }
    }

    function handleTrailerImageChange(direction) {
        if (trailerImageLookup.length == 0) {
            return;
        }
        let currentIndex = selectedTrailerImage.index;

        //true is next, false is previous
        if (direction) {
            if (currentIndex + 1 >= trailerImageLookup.length) {
                currentIndex = 0;
            } else {
                currentIndex += 1;
            }
        } else {
            if (currentIndex - 1 < 0) {
                currentIndex = trailerImageLookup.length - 1;
            } else {
                currentIndex -= 1;
            }
        }

        const newSelectedRow = tripTrailerImages[trailerImageLookup[currentIndex]["index"]];
        setSelectedTrailerImage({
            data: newSelectedRow["image_data"],
            index: currentIndex,
            name: newSelectedRow["displayname"],
            id: newSelectedRow["trailer_number"],
        });

        // Scroll to the newly selected row
        scrollRowIntoView( newSelectedRow["trailer_number"]);
    }

    function scrollRowIntoView(trailer_number){
        setTimeout(() => {
            const rowElement = tableContainerRef.current?.querySelector(
                `[data-row-key="${trailer_number}"]`
            );
            if (rowElement) {
                rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 0);
    }

    function handleModalCancel(){
        resetTripTrailerState();
    }

    return (
        <Modal
            title={"OCR Detected Trailer IDs - " + tripId}
            open={trailerImagesModal}
            onCancel={handleModalCancel}
            onOk={handleTripTrailerOk}
            width={"90%"}
        >
            <Row style={{ marginTop: 16, marginBottom: 16 }}>
                <Col span={18}>
                    <Row gutter={16} justify={"center"} align={"middle"}>
                        <Col span={2}>
                            <Button size="large"
                                icon={<LeftOutlined />}
                                onClick={() => handleTrailerImageChange(false)}>
                            </Button>
                        </Col>

                        <Col span={14} style={{ textAlign: "center" }}>
                            <Spin spinning={trailerSelectedLoading}>
                                {
                                    selectedTrailerImage ? (
                                        <>
                                            <img
                                                src={`data:image/jpeg;base64,${selectedTrailerImage.data}`}
                                                alt="Trailer Image"
                                                style={{ width: '100%', height: '200px', objectFit: 'contain' }}
                                            />
                                            <Text>Detected unit number - <span style={{fontWeight: 'bold'}}>{selectedTrailerImage.id}</span></Text> <br/>
                                            <Text>{selectedTrailerImage.name}</Text>
                                        </>
                                    ) : (
                                        <Text>No Image Available</Text>
                                    )}
                            </Spin>
                        </Col>

                        <Col span={2}>
                            <Button size="large"
                                icon={<RightOutlined />}
                                onClick={() => handleTrailerImageChange(true)}>

                            </Button>
                        </Col>
                    </Row>

                </Col>
                <Col span={6}>
                    <Col flex="auto"
                        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Text strong style={{ marginRight: 8 }}>Overwrite:</Text>
                        <Input
                            value={manualOverwrite.toUpperCase()}
                            onChange={(e) => setManualOverwrite(String(e.target.value).toUpperCase())}
                            disabled={invalidTrip ? true : manualOverwrite ? false : partialNumber ? false : userApprovedChecked}
                            style={{ flex: '1 1 auto' }}
                        />
                    </Col>
                    <Col flex="auto" style={{ marginTop: 16, display: "flex", flexDirection:"column" }}>
                        <Checkbox
                            checked={invalidTrip}
                            onChange={(e) => {
                                setInvalidTrip(e.target.checked); 
                                if(e.target.checked) setPartialNumber(false);
                            }}
                        >
                            Unreadable or invalid object
                        </Checkbox>
                        <Checkbox
                            checked={partialNumber}
                            onChange={(e) => {
                                setPartialNumber(e.target.checked); 
                                if(e.target.checked) setInvalidTrip(false);
                            }}
                        >
                            Partial 
                        </Checkbox>
                    </Col>
                    <Col flex="auto" style={{marginTop: 16}}>
                        <Link href={`#/trip_frames/${tripId}`} target="_blank">
                            View trip frames (new window)
                        </Link>
                    </Col>
                </Col>
            </Row>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            rowSelectedBg: '#ba80f0'
                        }
                    }
                }}
            >
                <div style={{ maxHeight: '400px', overflowY: 'auto' }} ref={tableContainerRef}>
                    <Table
                        dataSource={tripTrailerImages}
                        rowSelection={{ type: "radio", onChange: handleTrailerImageSelect, selectedRowKeys: selectedTrailerImage != null ? [selectedTrailerImage["id"]] : [] }}
                        columns={tripTrailerColumns}
                        rowKey="trailer_number"
                        bordered
                        pagination={false}
                        loading={trailerImagesLoading}
                    />
                </div>
            </ConfigProvider>
        </Modal>
    )
}
