import { useState, useEffect } from "react";
import { ExclamationCircleOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { DateTime } = require("luxon");

export function formatDate(date, seconds = true) {
    if (DateTime.isDateTime(date)) {
        if(seconds) return date.toFormat("yyyy-MM-dd'T'HH:mm:ss");
        else return date.toFormat("yyyy-MM-dd'T'HH:mm");
    }
    return "";
}

export function formatReadableDate(date) {
    if (DateTime.isDateTime(date)) {
        return date.toFormat("yyyy-MM-dd HH:mm");
    }
    return "";
}

export const DateUtils = {
    formatDate(date, seconds = true) {
      if (DateTime.isDateTime(date)) {
        return seconds
          ? date.toFormat("yyyy-MM-dd'T'HH:mm:ss")
          : date.toFormat("yyyy-MM-dd'T'HH:mm");
      }
      return "";
    },
  };

export const mergedTripLink = (trip) => {
    
    if (trip.merged_trips == undefined || trip.merged_trips <= 0) {
        return singleTripLink(trip.trip_id);
    }
    
    let queryString = "";
    const tripIds = trip.merged_trips.map(trip => trip.merged_trip_id);

    tripIds.forEach(tripId => {
        queryString = queryString + "trips[]=" + tripId + "&"
    });
    // remove the last &
    queryString = queryString.slice(0, -1);
    return <a href={"#/mergedtrip?" + queryString}>{tripIds.join(" + ")}</a>
}

export const singleTripLink = (tripId) => {
    return <a href={"#/singletrip/" + tripId}>{tripId}</a>
}
export const singleTripURL = (tripId) => {
    return "#/singletrip/" + tripId;
}

export function authHeader() {
    // Do not append jwt token when not using api auth
    if (!window.vtrack_config.USE_API_LOGIN) return null;
    const token = localStorage.getItem('token');
    if (token) {
        return 'Bearer ' + token;
    } else {
        return null;
    }
}

export function formatMinutes(minutesInput) {
    // Calculate the total number of seconds from the given minutes input
    let totalSeconds = Math.floor(minutesInput * 60);

    // Calculate the number of minutes
    let minutes = Math.floor(totalSeconds / 60);

    // Calculate the remaining seconds
    let seconds = totalSeconds % 60;


    // Display the result in "minutes:seconds" format
    return `${minutes} minutes ${seconds} seconds`;
}

export function getDuration(start, end) {
    let updated = new Date(end);
    let created = new Date(start);
    return (updated - created) / 1000 / 60;
}

export function getFormattedDuration(start, end) {
    const duration = getDuration(start, end);
    return formatMinutes(duration);
}

export function getTripDuration(trip) {
    if (!trip.trip_duration || trip.trip_duration == null || trip.trip_duration == undefined) {
        let updated = new Date(trip.updated_message_time);
        let created = new Date(trip.created_message_time);
        let trip_duration = getDuration(created, updated);
        return formatMinutes(trip_duration);
    }
    return formatMinutes(trip.trip_duration);

}

export const hexcolor_array = [
    "#FF0000", // Red
    "#00FF00", // Green
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#FF00FF", // Magenta
    "#00FFFF", // Cyan
    "#800000", // Maroon
    "#808000", // Olive
    "#008000", // Dark Green
    "#800080", // Purple
    "#008080", // Teal
    "#000080", // Navy
    "#FFA500", // Orange
    "#A52A2A", // Brown
    "#8A2BE2", // Blue Violet
    "#D2691E", // Chocolate
    "#DC143C", // Crimson
    "#2E8B57", // Sea Green
    "#8B4513", // Saddle Brown
    "#6A5ACD", // Slate Blue
]

export const tripTypeColor = {
    0: "gray",
    1: "#8c8cf7",   //UNLOAD
    2: "#02df02",   //LOAD
    3: "teal",      //YARD SHIFT

    4: '#e6c13c',   //VESSEL PICKUP
    5: '#e6c13c',   //YARD PICKUP
    6: '#e6c13c',
}

export function TripVerificationIcon(type) {
    if (type) {
        switch (type) {
            case 0: // Unverified (Red)
                return <ExclamationCircleOutlined style={{ color: 'red' }} />;
            case 1: // Verified (Green)
            case 2: // Modified (Green)
                return <CheckCircleOutlined style={{ color: 'green' }} />;
            case 3: // Invalid (Yellow)
            case 4: // Partial (Green)
                return <WarningOutlined style={{ color: 'orange' }} />;
            default:
                return null;
        }
    } else {
        return <ExclamationCircleOutlined style={{ color: 'red' }} />;
    }

}

export function NextcloudImage({ imageUrl, username, password }) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        // Create the Basic Authentication header
        const headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
        headers.set("User-Agent", "viztrack/1.0")
        headers.set("Access-Control-Allow-Origin", "*")

        // Fetch the image from Nextcloud
        fetch(imageUrl, { headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                // Create a URL from the Blob and set it as the image source
                const objectUrl = URL.createObjectURL(blob);
                setImageSrc(objectUrl);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });

        // Cleanup the object URL when the component is unmounted
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageUrl, username, password]);

    return (
        <div>
            {imageSrc ? (
                <img src={imageSrc} alt="Nextcloud file" />
            ) : (
                <p>Loading image...</p>
            )}
        </div>
    );
}

export function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}