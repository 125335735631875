import {useEffect} from "react";
import { createPortal } from "react-dom";
import { useMap } from "react-leaflet";
import L from "leaflet";

const LegendControl = ({ position = "bottomright" }) => {
  const map = useMap();

  const legendItems = [
    { color: "orange", label: "Unknown", "type": "parking_slot" },
    { color: "red", label: "Occupied", "type": "parking_slot" },
    { color: "green", label: "Free", "type": "parking_slot" },
    { color: "blue", label: "Allocated", "type": "parking_slot" },
    { color: "black", label: "Unofficial", "type": "parking_slot_unofficial" },
    { color: "#02df02", label: "Trip load", "type": "trip" },
    { color: "#8c8cf7", label: "Trip unload", "type": "trip" },
    { color: "teal", label: "Trip yard shift", "type": "trip" }
];

useEffect(() => {
    const legendControl = L.control({ position });

    legendControl.onAdd = () => {
      // Create a container for the React portal
      const div = L.DomUtil.create("div", "leaflet-legend-container");
      return div;
    };

    legendControl.addTo(map);

    const container = document.querySelector(".leaflet-legend-container");
    return () => {
      if (container) {
        map.removeControl(legendControl);
      }
    };
  }, [map, position]);

  // Render the JSX component into the Leaflet container
  const container = document.querySelector(".leaflet-legend-container");
  return container
    ? createPortal(
        <div
          id="map-legend"
          style={{
            padding: 10,
            border: "1px solid rgba(255, 255, 255, 0.4)",
            borderRadius: 8,
            background: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(5px)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ marginBottom: 8, fontWeight: "bold", color: "#333" }}>
            Legend
          </div>
          {legendItems.map((item, index) => {
            if (item.type === "parking_slot_unofficial") {
              return (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center", marginBottom: 4 }}
                >
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      marginRight: 8,
                      border: "3px dotted #000",
                    }}
                  ></div>
                  <div style={{ color: "#333" }}>{item.label}</div>
                </div>
              );
            }
            if (item.type === "parking_slot") {
              return (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center", marginBottom: 4 }}
                >
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      background: item.color,
                      marginRight: 8,
                      border: "1px solid #000",
                    }}
                  ></div>
                  <div style={{ color: "#333" }}>{item.label}</div>
                </div>
              );
            }
            if (item.type === "trip") {
              return (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center", marginBottom: 4 }}
                >
                  <div
                    style={{
                      width: 22,
                      height: 6,
                      background: item.color,
                      marginRight: 8,
                    }}
                  ></div>
                  <div style={{ color: "#333" }}>{item.label}</div>
                </div>
              );
            }
            return null;
          })}
        </div>,
        container
      )
    : null;
};

export default LegendControl;
