import React, { useEffect, useState } from "react";
const { DateTime } = require("luxon");

import { MoreOutlined, LinkOutlined } from '@ant-design/icons';
import { Table, Tooltip, Popconfirm, message } from 'antd';


export default function SingleTripMergeTable(props){

    const [rowSelected, setRowSelected] = useState(false)
    const [currentpageRows, setCurrentpageRows] = useState([])
    const [eventsData, setEventsData] = useState([])
    const [serverSidePage, setServerSidePage] = useState(1)


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0){
                setRowSelected(true)
                props.setDataOnPage(selectedRows)
            } else {
                setRowSelected(false)
                props.setDataOnPage(currentpageRows)
            }
            
        },
        getCheckboxProps: (record) => ({
        // Column configuration not to be checked
        name: record.name,
        }),
    };
    useEffect(() => {
        if(props.data && props.data.events){
            setCurrentpageRows(props.data.events.slice(0,10));
            setEventsData(props.data.events);
        }
    }, [props.data])

    const columns = [
        { title: 'Created', dataIndex: ["payload", "message_time"], key: 'created', width: 160,
            defaultSortOrder: 'ascend',
            render: val => !!val ? DateTime.fromISO(val, {zone: "utc"}).toFormat('dd/MM/yyyy HH:mm:ss') : val,
            sorter: (a, b) => DateTime.fromISO(a.payload.message_time, {zone: "utc"}) - DateTime.fromISO(b.payload.message_time, {zone: "utc"}),
            sortDirections: ['ascend', 'descend'],
        },
        { title: 'ID', dataIndex: 'id', key: 'id', width: 85},
        {
            title: "Trip ID", dataIndex: ["trip_id"], key: "trip_id"
        },   
        { title: 'Message Type', dataIndex: 'message_type',
            filters: [{text: "status", value: "status"}, {text: "pickup", value: "trailer/pickup"}, {text: "delivery", value: "trailer/delivery"}],
            onFilter: (value, record) => record.message_type.indexOf(value) === 0},
        { title: 'Topic', dataIndex: 'topic', key: 'topic', width: 220},
        { title: 'Tractor ID', dataIndex: 'tag', key: 'tag', width: 100},
        { title: 'Payload', dataIndex: 'payload', key: 'payload', width: 400, 
            render: (val, record) =>
                <React.Fragment>
                    {JSON.stringify(val).slice(0,55) + '...'}
                    {JSON.stringify(val).length > 55 &&
                    <Popconfirm
                        cancelText="Hide"
                        overlayInnerStyle={{maxWidth:600}}
                        okText="Copy"
                        icon={<LinkOutlined />}
                        onConfirm={() => {
                            window.navigator.clipboard.writeText(JSON.stringify(val));
                            message.success("Payload copied to clipboard!");
                        }}
                        title={JSON.stringify(val, null, 2)}
                        placement="left"
                    >
                        <Tooltip title="View payload"><MoreOutlined /></Tooltip>
                    </Popconfirm>}
                </React.Fragment>
        },
    ];

    async function tableChangeHandler(pagination, filters, sorter, extra){
        const { current, pageSize } = pagination;
        const totalPages = Math.ceil((props.data.page_size * serverSidePage) / pageSize);
        if (current === totalPages) {
            //user is on the last page
            await handleLastPage();
        }

        if (rowSelected) return
        const startIndex = (current - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const newData = extra.currentDataSource.slice(startIndex, endIndex);
        props.setDataOnPage(newData)
        setCurrentpageRows(newData)
    }

    async function handleLastPage(){
        const nextPage = await props.getTripEventsAtPage(serverSidePage + 1);
        setServerSidePage(nextPage.page)
        setEventsData(prevEventsData => [...prevEventsData, ...nextPage.events]);
    }


    return (
        <Table
            columns={columns}
            loading={props.loading}
            pagination={{ pageSizeOptions: ['2', '10', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
            bordered={true}
            rowKey="id"
            dataSource={[...eventsData]}
            size="small"
            scroll={{y: props.scroll - 44}}
            onChange={tableChangeHandler}
            rowSelection={{
                type: "checkbox",
                ...rowSelection
            }}
        />
    )

}
